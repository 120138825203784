import {
    useQuery
} from "@tanstack/react-query";
import { PageRequest, PagedResult } from "models/common";
import { pageRequestToUrlSearchParams } from "util/helpers";

import { useAuthenticatedRequest } from "./useRequests";

export interface UserAddress {
    addressLine1?: string;
    addressLine2?: string | null;
    city?: string;
    state?: string;
    zipCode?: string;
}

// There's a very similar type in models/profile, but not identical
export interface StaffUsersProfile {
    id: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    userName?: string;
    phoneNumber?: string;
    address: UserAddress;
    zendeskUserId?: string | null;
    imageUri: string | null;
    created?: string;
    roles?: string[];
    active: boolean;
    balanceInCents: number;
    recentPackageCount: number;
    recentSalesInCents: number;
    initials: string | null;
}

const makeAxiosRequest = (pageRequest: PageRequest) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    return {
        method: "get",
        url: `/profile/all?${options}`,
    };
};

export const useUsersCSV = (pageRequest: Omit<PageRequest, "page">) => {
    const pageRequestWithCSV = {
        ...pageRequest,
        page: 1, // ignored
        format: "csv",
    } as const;

    const axiosRequest = makeAxiosRequest(pageRequestWithCSV);

    return useAuthenticatedRequest<string>(axiosRequest);
};

const useUsers = (
    pageRequest: PageRequest
) => {
    const key = ["users", pageRequest];

    const getUsers = useAuthenticatedRequest<PagedResult<StaffUsersProfile>>(
        makeAxiosRequest(pageRequest)
    );

    return useQuery(key, getUsers);
};

export default useUsers;
