import { BellFilled } from "@ant-design/icons";
import { HamburgerIcon } from "@chakra-ui/icons";
import {
    Box,
    BoxProps,
    forwardRef,
    HStack,
    Icon,
    Image,
    Menu,
    MenuItem,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { CustomerRoutes, PublicRoutes } from "appRoutePaths";
import DisclosureStack from "components/DisclosureStack";
import { ImpersonateModal } from "components/ImpersonateModal";
import { useIdentity } from "context/auth";
import { CssVariables, NoPrintSx } from "models/common";
import React, { FC, useCallback, useMemo, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import DisableMFA from "components/customer/Profile/DisableMFA";
import EnableMFA from "components/customer/Profile/EnableMFA";
import useProfile from "hooks/useProfile";
import useWebsiteSettings from "hooks/useWebsiteSettings";
import { getUserDashboardUrl } from "layouts/useIdentityRedirection";
import LoginButton from "../HomePage/LoginButton";

const HeaderLogo = require("images/logo_white_and_blue.png");

export const NavHeader = () => {
    const { isOpen, onToggle, onClose } = useDisclosure();

    return (
        <Menu>
            <Box width="100%" pos="relative" sx={NoPrintSx}>
                <VStack spacing={0}>
                    <HStack
                        align="center"
                        spacing={4}
                        bg="darkGray"
                        color="white"
                        py="1rem"
                        px="1.5rem"
                        justifyContent="space-between"
                        width="100%"
                    >
                        <Link to="/" onClick={onClose}>
                            <Image
                                src={HeaderLogo}
                                alt="DC Sports 87 Logo"
                                height="3rem"
                            />
                        </Link>
                        <DesktopNavItems />
                        <MobileNavMenu
                            isOpen={isOpen}
                            onClose={onClose}
                            onToggle={onToggle}
                        />
                    </HStack>
                    <Announcement />
                </VStack>
            </Box>
        </Menu>
    );
};

const Announcement = () => {
    const { settings } = useWebsiteSettings();

    if (
        !settings ||
        !settings.announcementEnabled ||
        settings.announcement.trim().length === 0
    ) {
        return null;
    }

    return (
        <Text
            fontSize={18}
            align="center"
            py={1}
            width="100%"
            bgColor={CssVariables.darkGreen}
            textColor={CssVariables.white}
        >
            {settings.announcement}
        </Text>
    );
};

export type MenuItemType = {
    label: string;
    icon?: any;
    onClick?: () => void;
    href?: string;
    as?: string;
    needsLoggedIn?: boolean;
    needsLoggedOut?: boolean;
    needsAdmin?: boolean;
};

const useMenuItems = () => {
    const { userName, isLoggedIn, logout } = useIdentity();
    const navigate = useNavigate();

    const list: MenuItemType[] = useMemo(() => {
        const items: MenuItemType[] = [
            {
                label: "Log In",
                href: PublicRoutes.login.url,
                needsLoggedOut: true,
            },
            {
                label: "Sign Up",
                href: PublicRoutes.signUp.url,
                needsLoggedOut: true,
            },
            {
                label: userName ?? "Account",
                href: CustomerRoutes.dashboard.url,
                needsLoggedIn: true,
                // TODO: Child items? No href maybe? Account page?
            },
            {
                label: "How it works",
                href: "/how-process-works",
            },
            {
                label: "Sell with us",
                href: "/sell-with-us",
            },
            {
                label: "Featured cards",
                href: "/featured-auctions",
            },
            {
                label: "FAQ",
                href: "/faq",
            },
            {
                icon: <Icon as={BellFilled} />,
                label: "Notifications",
                href: "/notifications",
                needsLoggedIn: true,
            },
            {
                label: "Log Out",
                onClick: logout,
                needsLoggedIn: true,
            },
        ];

        return (
            items
                .filter(({ needsLoggedIn }) => !needsLoggedIn || isLoggedIn)
                // .filter(({ needsAdmin }) => !needsAdmin || isAdmin)
                .filter(({ needsLoggedOut }) => !needsLoggedOut || !isLoggedIn)
                .map<MenuItemType>(({ href, onClick, ...rest }) => ({
                    ...rest,
                    href,
                    onClick:
                        onClick ?? (href ? () => navigate(href) : undefined),
                }))
        );
    }, [isLoggedIn, userName, navigate, logout]);

    return list;
};

const MobileNavMenu: FC<{
    isOpen: boolean;
    onClose: () => void;
    onToggle: () => void;
}> = ({ isOpen, onClose, onToggle }) => {
    const btnRef = useRef(null);
    const menuItems = useMenuItems();

    return (
        <>
            <HamburgerIcon
                ref={btnRef}
                onClick={onToggle}
                bg="transparent"
                fontSize="32"
                cursor="pointer"
                display={{
                    base: "unset",
                    // Don't show the hamburger menu at all if they're not on a mobile sized screen
                    md: "none",
                }}
            />
            <DisclosureStack isOpen={isOpen}>
                {menuItems.map(({ label, href, icon, onClick }, index) => (
                    <NavItemsMenuItem
                        key={label}
                        onClick={() => {
                            onClose();
                            if (onClick) {
                                onClick();
                            }
                        }}
                    >
                        {label}
                    </NavItemsMenuItem>
                ))}
                {/* Padding that doesn't add to the collapsed size lol, thanks box-sizing: border-box */}
                <Box height="1rem" />
            </DisclosureStack>
        </>
    );
};

const NavItemsMenuItem = forwardRef<BoxProps, typeof MenuItem>(
    ({ children, ...props }, ref) => {
        return (
            <Box
                fontWeight="semibold"
                color="white"
                ref={ref}
                borderY="1 solid whitesmoke"
                py={3}
                pr={"6rem"}
                pl="2.5rem"
                fontSize="1.2rem"
                width="100%"
                cursor="pointer"
                _hover={{
                    bg: "gray",
                }}
                {...props}
            >
                {children}
            </Box>
        );
    }
);

const ImpersonateMenuItem = forwardRef<BoxProps, any>((props, ref) => {
    const { isOpen, onToggle, onClose } = useDisclosure();

    const { isAdmin } = useIdentity();
    if (!isAdmin) {
        return null;
    }

    return (
        <>
            <NavItemsMenuItem ref={ref} {...props} onClick={onToggle}>
                Impersonate
            </NavItemsMenuItem>
            <ImpersonateModal isVisible={isOpen} onClose={onClose} />
        </>
    );
});

// NOTE: Both the admin and customer nav items use the same component, this one.
export const LoggedInNavMenu: FC = () => {
    const { userName, logout, twoFactorSetupRequired, twoFactorTokenRequired } = useIdentity();
    const { data: profile, refetch } = useProfile();

    // This hook handles giving admins and customers the correct nav items for their role
    const { isOpen, onToggle, onClose } = useDisclosure();

    const { roles, isCustomer, isAdmin } = useIdentity();
    const navigate = useNavigate();

    const goToDashboard = () => {
        const dashboardUrl = getUserDashboardUrl(roles);
        navigate(dashboardUrl);
    };

    const profileAction = useCallback(() => {
        onClose();
        navigate(CustomerRoutes.profile.url);
    }, [onClose, navigate]);

    const ordersAction = useCallback(() => {
        onClose();
        navigate(CustomerRoutes.orders.url);
    }, [onClose, navigate]);

    const staffOrTyper = !isCustomer && !isAdmin;

    return (
        <>
            <Box cursor="pointer" onClick={onToggle} fontSize="24px">
                {userName}
            </Box>

            <DisclosureStack isOpen={isOpen}>
                <NavItemsMenuItem
                    onClick={() => {
                        onClose();
                        goToDashboard();
                    }}
                >
                    Dashboard
                </NavItemsMenuItem>

                {!twoFactorSetupRequired && !twoFactorTokenRequired && <ImpersonateMenuItem onClick={onClose} />}

                {isCustomer && (
                    <>
                        <NavItemsMenuItem onClick={profileAction}>
                            Profile
                        </NavItemsMenuItem>
                        <NavItemsMenuItem onClick={ordersAction}>
                            Orders
                        </NavItemsMenuItem>
                    </>
                )}

                {staffOrTyper && profile && !profile.requireTwoFactor &&
                    <EnableMFA
                        onEnabled={refetch}
                        emailConfirmed={profile.verifiedEmail}
                        trigger={<NavItemsMenuItem>Enable 2FA Auth</NavItemsMenuItem>}
                    />
                }

                {staffOrTyper && profile && profile.requireTwoFactor &&
                    <DisableMFA
                        onDisabled={refetch}
                        trigger={<NavItemsMenuItem>Remove 2FA Auth</NavItemsMenuItem>}
                    />
                }
                <NavItemsMenuItem onClick={logout}>Log Out</NavItemsMenuItem>
            </DisclosureStack>
        </>
    );
};

const DesktopNavItems: FC = () => {
    const { isLoggedIn, isCustomer } = useIdentity();
    const location = useLocation();

    return (
        <HStack
            display={{
                base: "none",
                md: "flex",
            }}
            align="center"
            spacing={10}
        >
            {isCustomer && (
                <NavLink
                    to={CustomerRoutes.shop.url}
                    isActive={location.pathname === CustomerRoutes.shop.url}
                >
                    Shop
                </NavLink>
            )}
            <NavLink
                to="/how-process-works"
                isActive={location.pathname === "/how-process-works"}
            >
                How it works
            </NavLink>
            <NavLink
                to="/sell-with-us"
                isActive={location.pathname === "/sell-with-us"}
            >
                Sell with us
            </NavLink>
            <NavLink
                to={PublicRoutes.featuredAuctions.url}
                isActive={
                    location.pathname === PublicRoutes.featuredAuctions.url
                }
            >
                Featured cards
            </NavLink>
            <NavLink to="/faq" isActive={location.pathname === "/faq"}>
                FAQ
            </NavLink>
            {isLoggedIn ? <LoggedInNavMenu /> : <LoginButton />}
        </HStack>
    );
};

interface NavLinkProps {
    to: string;
    isActive: boolean;
    children: React.ReactNode;
}

const NavLink: FC<NavLinkProps> = ({ to, isActive, children }) => {
    return (
        <Link
            to={to}
            style={{
                fontWeight: isActive ? "bold" : "normal",
                fontSize: "24px",
            }}
        >
            {children}
        </Link>
    );
};
